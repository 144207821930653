import { SECTION_CATEGORY } from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getArchiveUrl } from '../get-archive-url';
import getPostUrl from '../get-post-url';
import { getLastPage } from '../pagination';
import { createArchivePageUrl } from '../navigation';
import { getPaginationUrls } from './get-pagination-item-data';

const getMonthLocale = (month, locale) => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(parseInt(month, 10) - 1);
  return date.toLocaleString(locale, { month: 'long' });
};

export const generateArchiveSEOTags = ({ sectionUrl, state, page, posts, year, month, locale }) => {
  const archiveUrl = getArchiveUrl(sectionUrl, year, month);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const items = {
    numberOfItems: entityCount,
    itemUrls: posts.map((post) => getPostUrl(state.topology, [post.slug])),
  };

  const pagination = {
    ...getPaginationUrls(archiveUrl, page, lastPage, state),
    totalPages: parseInt(Math.ceil(entityCount / pageSize), 10),
    currentPage: page,
  };

  const itemData = {
    monthNumber: month,
    monthName: getMonthLocale(month, locale),
    year,
    url: createArchivePageUrl(sectionUrl, year, month, page),
    pagination,
    items,
  };
  return {
    itemType: 'BLOG_ARCHIVE', // constant is not provided by advanced-seo-utils
    itemData: { archive: itemData },
  };
};
